import React from "react";
import Helmet from "../../../components/helmet/Helmet";
import { services } from "../../../assets/data/services";
import { useState } from "react";
import CommonSection from "../../../components/common-section/CommonSection";
import { Link } from "react-router-dom";
import proMassage from "../../../assets/images/pro-massage.png";

const LymphaticDrainageMassage = () => {
  const [pageData, setPageData] = useState(
    services.find((item) => item.id === "lymphatic-drainage-massage")
  );

  return (
    <>
      <Helmet
        title={"Lymphatic Drainage Massage in Kennesaw, GA | Massage Now"}
        desc={
          "Discover the benefits of Lymphatic Drainage Massage at Massage Now in Kennesaw, GA. Our specialized therapy helps detoxify, reduce swelling, and improve circulation for a healthier you. Book your session today!"
        }
        canonical={"https://www.massagenow-atl.com/massage-services/lymphatic-drainage-massage"}
      />

      <CommonSection title={"Lymphatic Drainage Massage"} />

      <div className="services">
        <div className="pro-massage-img">
          <img src={proMassage} alt="professional massage services" />
        </div>

        <div id={pageData.id} className="service-section">
          <div className="image">
            <img src={pageData.img} alt="service-img" />
          </div>
          <div className="content">
            <h2>{pageData.title}</h2>
            <p className="desc">{pageData.desc}</p>
            <ul>
              {pageData.prices.map((item, index) => (
                <li>{item}</li>
              ))}
            </ul>
            {pageData.styles ? (
              <p className="styles">Massage Styles: {pageData.styles}</p>
            ) : (
              ""
            )}
            <button>
              <Link
                onclick="return gtag_report_conversion('tel:+16783880866');"
                to="tel:+16783880866"
              >
                Book an Appointment
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LymphaticDrainageMassage;

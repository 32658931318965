import s1Thumbnail from "../../assets/images/services/s1-thumbnail.webp";
import s1Img from "../../assets/images/services/s1-img.webp";
import s1Icon from "../../assets/images/services/s1-icon.svg";

import s2Thumbnail from "../../assets/images/services/s2-thumbnail.webp";
import s2Img from "../../assets/images/services/s2-img.webp";
import s2Icon from "../../assets/images/services/s2-icon.svg";

import s3Thumbnail from "../../assets/images/services/s3-thumbnail.webp";
import s3Img from "../../assets/images/services/s3-img.webp";
import s3Icon from "../../assets/images/services/s3-icon.svg";

import s4Thumbnail from "../../assets/images/services/s4-thumbnail.webp";
import s4Img from "../../assets/images/services/s4-img.webp";
import s4Icon from "../../assets/images/services/s4-icon.svg";

import s5Thumbnail from "../../assets/images/services/s5-thumbnail.webp";
import s5Img from "../../assets/images/services/s5-img.webp";
import s5Icon from "../../assets/images/services/s5-icon.svg";

import s6Thumbnail from "../../assets/images/services/s6-thumbnail.webp";
import s6Img from "../../assets/images/services/s6-img.webp";
import s6Icon from "../../assets/images/services/s6-icon.svg";

import s7Thumbnail from "../../assets/images/services/s7-thumbnail.png";
import s7Img from "../../assets/images/services/s7-img.png";
import s7Icon from "../../assets/images/services/s6-icon.svg";

export const services = [
  {
    thumbnail: s1Thumbnail,
    icon: s1Icon,
    img: s1Img,
    title: "BODY MASSAGE",
    desc: "full-body relaxation and rejuvenation, promotes blood flow, and eases muscle tension.",
    offPageDesc:
      "Body massage is a soothing experience that helps in relaxation and rejuvenation. Our skilled massage therapists use various techniques to relieve muscle tension and improve blood circulation. And this full body massage helps to reduce stress, promote better sleep, and enhance overall well-being.",
    prices: ["1/2 hr: $50", "1 hour: $80", "90 min: $115", "2 hours: $150"],
    styles: "Swedish, Hot Stone, Deep Tissue, Sports",
    id: "body-massage",
    link: "/massage-services/body-massage",
  },

  {
    thumbnail: s2Thumbnail,
    icon: s2Icon,
    img: s2Img,
    title: "COUPLE'S MASSAGE",
    desc: "luxurious, relaxing, and intimate experience for two in a tranquil setting.",
    offPageDesc:
      "Experience a special bonding with your partner through our couples massage. You both get massages in the same room with your own therapist. Relax and have fun together in a comfortable and calm space. It's a great way to spend time and feel good together. Our friendly therapists will take care of you both.",
    prices: [
      "Price Per Couple",
      "1 hour: $160",
      "90 min: $230",
      "2 hours: $300",
    ],
    styles: "Swedish, Hot Stone, Deep Tissue, Sports",
    id: "couples-massage",
    link: "/massage-services/couples-massage",
  },

  {
    thumbnail: s3Thumbnail,
    icon: s3Icon,
    img: s3Img,
    title: "SPORTS MASSAGE",
    desc: "A sports massage helps improve your performance, reduce muscle soreness, increase flexibility, and prevent injuries, leaving you feeling relaxed and ready to tackle your next challenge.",
    offPageDesc:
      "For athletes who lead an active lifestyle, sports massage is one of the best choices as It helps athletes in improving their flexibility, preventing injuries, and improving their performance levels. Our skilled therapists employ a combination of techniques including targeted muscle work and stretching to help you improve your performance in your sport. ",
    prices: ["1 hour: $90", "90 min:$125"],
    id: "sports-massage",
    link: "/massage-services/sports-massage",
  },

  {
    thumbnail: s4Thumbnail,
    icon: s4Icon,
    img: s4Img,
    title: "MASSAGE COMBOS",
    desc: "a combination of different massage techniques for a full-body experience of relaxation and rejuvenation.",
    offPageDesc:
      "We also offer massage combos. Our massage combo is a combination of different massage techniques and provides a full body massage. It rejuvenates and relaxes your whole body.",
    prices: [
      "30 min body + 30 min foot: $80",
      "1 hr body + 30 min foot: $115",
      "90 min body + 30 min foot: $150",
    ],
    id: "combo-massage",
    link: "/massage-services/massage-combos",
  },

  {
    thumbnail: s5Thumbnail,
    icon: s5Icon,
    img: s5Img,
    title: "4 HANDS MASSAGE",
    desc: "indulgent, synchronized treatment by two therapists for ultimate relaxation and tension relief",
    offPageDesc:
      "indulgent, synchronized treatment by two therapists for ultimate relaxation and tension relief",
    prices: ["1 hour: $160", "90 min: $230", "2 hours: $300"],
    styles: "Swedish, Hot Stone, Deep Tissue, Sports ",
    id: "four-hands-massage",
    link: "/massage-services/four-hands-massage",
  },

  {
    thumbnail: s6Thumbnail,
    icon: s6Icon,
    img: s6Img,
    title: "CHAIR MASSAGE",
    desc: "quick and effective stress-relief for the back, neck, and shoulders, done while seated in a special massage chair",
    offPageDesc:
      "quick and effective stress-relief for the back, neck, and shoulders, done while seated in a special massage chair",
    prices: ["10 min: $20", "15 min: $25", "20 min: $30"],
    styles: "Reflexology, Sports",
    id: "chair-massage",
    link: "/massage-services/chair-massage",
  },

  {
    thumbnail: s7Thumbnail,
    icon: s7Icon,
    img: s7Img,
    title: "LYMPHATIC DRAINAGE MASSAGE",
    desc: "Experience the revitalizing power of lymphatic massage at Massage Now - our experienced licensed therapist will gently stimulate your lymphatic system, boosting your immunity, detoxifying your body, and leaving you feeling refreshed, rejuvenated, and ready to conquer your day!",
    offPageDesc:
      "Experience the revitalizing power of lymphatic massage at Massage Now - our experienced licensed therapist will gently stimulate your lymphatic system, boosting your immunity, detoxifying your body, and leaving you feeling refreshed, rejuvenated, and ready to conquer your day!",
    prices: ["60 min: $90", "90 min: $125"],
    styles: "Reflexology, Sports",
    id: "lymphatic-drainage-massage",
    link: "/massage-services/lymphatic-drainage-massage",
  },
];
